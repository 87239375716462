import React from 'react'
import { connect } from 'react-redux'
import { getLvpLayout } from '@chegg-wt/core/components/Layouts/LargeVideoPlayerLayout'
import { NextPage } from 'next'
import Home, { HomeTop } from '@chegg-wt/core/components/Layouts/Home'
import { TopContentWrapper } from '@chegg-wt/core/components/AdFrame/'
import { useRoutesContext } from '@chegg-wt/core/components/RoutesContext'
import SEOTitle from '@chegg-wt/core/components/SEOTitle'
import withApollo from '@chegg-wt/core/api/graphApi'
import { ApolloProviderWithLayout } from '@chegg-wt/core/types/layout'
import { useGeoLocation } from '@chegg-wt/core/hooks/useGeoLocation'
import { lvpLayoutSelector } from '@chegg-wt/core/state/featureFlags/selectors'
import { AppState } from '@chegg-wt/core/state'
import { useOnUnloadTimeOnPage } from '@chegg/time-on-page'
import {
  plusTabs as plusTabsFn,
  oldPricingTableData as oldPricingTableDataFn,
  newPricingTableData as newPricingTableDataFn,
  cards,
} from '../copy/Home'
import resources from '../copy/resources'
import { upgradePromotionCopy } from '../copy/upgrade'

const resourceTitle = 'Welcome to Cite This For Me™'

interface Props {
  isLvp: boolean
}

const HomePage: NextPage<Props> = ({ isLvp }) => {
  const { toPlagiarism } = useRoutesContext()
  const { countryCode } = useGeoLocation()
  const plusTabs = plusTabsFn({ toPlagiarism })
  const oldPricingTableData = oldPricingTableDataFn({ countryCode })
  const newPricingTableData = newPricingTableDataFn({ countryCode })

  useOnUnloadTimeOnPage()

  return (
    <>
      <SEOTitle title="Cite This For Me: Harvard, APA, MLA Reference Generator" />
      {!isLvp && (
        <TopContentWrapper>
          <HomeTop cards={cards} />
        </TopContentWrapper>
      )}
      <Home
        copy={{
          plusTabs,
          resources,
          resourceTitle,
          oldPricingTableData,
          newPricingTableData,
          upgradePromotionCopy,
        }}
      />
    </>
  )
}

const HomePageWithApollo: ApolloProviderWithLayout = withApollo()(HomePage)
HomePageWithApollo.getPageLayout = (page) =>
  getLvpLayout(page, () => <HomeTop cards={cards} />)

const mapStateToProps = (state: AppState) => ({
  isLvp: lvpLayoutSelector(state),
})
export default connect<Props>(mapStateToProps)(HomePageWithApollo)
