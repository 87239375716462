import * as Styled from '@chegg-wt/core/components/Resources/styled'
import { Config } from '@chegg-wt/core/config'
import { Resource } from '@chegg-wt/core/types/core'

const resources: Resource[] = [
  {
    header: 'About Cite This For Me™',
    summary: (
      <>
        Cite This For Me™ was launched in October 2010, we began with the mission of
        helping students quickly create citations. Since then, the Cite This For Me™
        citation generator has assisted millions of students across the world including in
        the United Kingdom, Canada, United States, Australia, and beyond.
        <br />
        <br />
        Our tools are designed to help you quickly prepare an entire bibliography or
        reference list. Even if you know very little about references, our forms and
        automatic citation features can help guide you through the process and tell you
        what information is needed. This means less guessing for you and an easier
        citation process!
        <br />
        <br />
        Nearly any style you can think of is supported by the Cite This For Me™ citation
        generator, including Harvard referencing, APA (American Psychological Association)
        style, MLA (Modern Language Association) style, Chicago style, Vancouver, and
        thousands of others.
      </>
    ),
    dataTestId: 'about-link',
  },
  {
    header: 'Why citing matters',
    summary: (
      <>
        Citing isn’t something you usually think about, but it’s important nonetheless.
        You already do it in your everyday life without realising it. Have you ever said,
        “I heard on XYZ News that . . . “, or “I read in XYZ that those two celebrities
        are dating”, or even “Mom said that you can’t do that”. By saying where you got
        your information, you are casually citing a source.
        <br />
        <br />
        We do this because it gives credibility to what we say, but also because it
        credits the originator of the information. It also allows others to follow up if
        they need more information. Formal citing done for papers and projects takes this
        a step further. In addition to the reasons mentioned above, citing sources in
        academia provides evidence of your research process and helps you avoid
        plagiarism.
        <br />
        <br />
        Plagiarism is a word you never want to hear describing your work. You’ve probably
        seen headlines in the news and heard stories in school about the negative
        consequences of plagiarism. It’s not good but it is preventable. By creating
        references and citations with Cite This For Me™ tools you’re taking steps to help
        avoid this.
      </>
    ),
    dataTestId: 'why-citing-link',
  },
  {
    header: 'Start citing easily with Cite This For Me™',
    summary: (
      <>
        Click the button “Create citations” to begin. You’ll be prompted to choose a
        source type and guided through the rest of the citing process. For source types
        like websites, journal articles, and books, the Cite This For Me™ citation
        generator automatically tries to find your source’s information based on details
        you provide. That could be anything from the author’s name to the source’s URL to
        the article’s DOI number. This makes citing more efficient and helps you easily
        create references and citations for your paper in a timely manner.
      </>
    ),
    dataTestId: 'start-citing-link',
  },
  {
    header: 'Citation guides: Understanding it all',
    summary: (
      <>
        Beyond simply creating references or citations, most citation styles have
        additional guidelines about paper formatting, in-text citations, and other
        details. Cite This For Me™{' '}
        <Styled.CardTitleLink href={`${Config.baseUrl}/guides`}>
          citation guides
        </Styled.CardTitleLink>{' '}
        cover a lot of this additional information, so your paper is more properly prepped
        and less likely to get points taken off for these details. The guides cover
        several citation styles, but the most popular are{' '}
        <Styled.CardTitleLink href={`${Config.baseUrl}/uk/referencing-generator/harvard`}>
          Harvard referencing
        </Styled.CardTitleLink>
        ,{' '}
        <Styled.CardTitleLink href={`${Config.baseUrl}/us/citation-generator/apa`}>
          APA format
        </Styled.CardTitleLink>
        ,{' '}
        <Styled.CardTitleLink href={`${Config.baseUrl}/us/citation-generator/mla`}>
          MLA format
        </Styled.CardTitleLink>
        , and{' '}
        <Styled.CardTitleLink href={`${Config.baseUrl}/us/citation-generator/chicago`}>
          Chicago style
        </Styled.CardTitleLink>
        .
      </>
    ),
    dataTestId: 'citation-guide-link',
  },
  {
    header: 'Looking for more? Check out Cite This For Me™ Premium',
    summary: (
      <>
        A good paper references several sources. Multiply that with the several papers
        most schools assign in a year, and you get dozens of sources that will need to be
        cited within your academic career. That’s a lot of references to create, sort
        through, and keep track of.
        <br />
        <br />
        That’s where Cite This For Me™ Premium comes in. With a premium account you can
        cite as many sources as you want, organize the sources into bibliographies, and
        save ALL of those bibliographies so you can easily refer back to your references.
        It’s a great way to manage your bibliographies and cite with confidence.
      </>
    ),
    dataTestId: 'looking-for-more-link',
  },
]

export default resources
