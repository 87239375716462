import { getLocation } from '@chegg-wt/core/utils/geoLocation'
import { ProductConfig } from '@chegg/wt-checkout'
import { Location, LocationPlan } from '@chegg-wt/core/types/upgrade'

export const LocationBasedPlan: LocationPlan = {
  [Location.US]: { itemId: 'CHGMnthCTFMNFT', price: '$9.99' },
  [Location.AU]: { itemId: 'CHGMnthCTFMNFTAUD', price: '$12.99' },
  [Location.EU]: { itemId: 'CHGMnthCTFMNFTEUR', price: '€8.99' },
  [Location.GB]: { itemId: 'CHGMnthCTFMNFTGBP', price: '£6.99' },
}

export const BASE_PRICE = 9.95

export const getProductConfig = (location: string): ProductConfig => {
  const locationToUse = getLocation(location)
  return {
    itemId: LocationBasedPlan[locationToUse].itemId,
    productCode: 'CTFM',
    basePrice: BASE_PRICE,
  }
}
