import { TYPE_CITATIONS, TYPE_PLAGIARISM } from '@chegg-wt/core/constants/plusTab'
import { TYPE_CREATE_CITATIONS, TYPE_CHECK_PAPER } from '@chegg-wt/core/constants/card'
import { routeToHref } from '@chegg-wt/core/utils/routes'
import {
  CREATE_CITATIONS_CONTEXT_ID,
  CHECK_PAPER_CONTEXT_ID,
} from '@chegg-wt/core/components/HeaderWithCards/testIds'
import * as tabTestIds from '@chegg-wt/core/components/PlusTab/testIds'
import {
  PRICING_TABLE_FREE,
  CHECK,
  PRICING_TABLE_PLUS,
  CITATION_STYLE,
  EXPERT_PAPER_HELP,
  GRAMMAR_CHECKS,
  PLAGIARISM_DETECTION,
  SAVE_CITATIONS,
  ADD_FREE_EXPERIENCE,
} from '@chegg-wt/core/constants/pricingTable'
import { getLocation } from '@chegg-wt/core/utils/geoLocation'
import { HomeCopy, PlusTabs, PricingTable } from '@chegg-wt/core/types/core'
import { LocationBasedPlan } from '../config/pricingPlans'

export const cards: HomeCopy = {
  header: 'Create Harvard, APA & MLA citations',
  createCitations: {
    imgFilename: 'card-create-citations.svg',
    buttonText: 'Create citations',
    context: {
      value: 'Start a new citation or manage your existing projects.',
      contextTestId: CREATE_CITATIONS_CONTEXT_ID,
    },
    dataTestId: 'create-citations-btn',
    type: TYPE_CREATE_CITATIONS,
  },
  checkPaper: {
    imgFilename: 'card-check-your-paper.svg',
    buttonText: 'Check your paper',
    context: {
      value: 'Scan your paper for plagiarism.',
      contextTestId: CHECK_PAPER_CONTEXT_ID,
    },
    dataTestId: 'check-paper-btn',
    type: TYPE_CHECK_PAPER,
  },
}

export const plusTabs = ({ toPlagiarism }): PlusTabs => ({
  header: 'Cite smarter, worry less with Cite This For Me™ Premium',
  subHeader: 'Upgrade to save your work, check for plagiarism, and more!',
  tabs: [
    {
      label: 'References',
      imgFilename: 'eb-tabs-citations.png',
      alt: 'cite using APA, MLA, Chicago and more',
      to: '/harvard/source-type',
      header: 'Cite sources the easy way',
      context:
        'Easily create references with our citation generator for 50+ source types.',
      dataTestId: tabTestIds.CITATIONS_TAB,
      type: TYPE_CITATIONS,
    },
    {
      label: 'Plagiarism',
      imgFilename: 'eb-tabs-plagiarism.png',
      alt: 'check for potential plagiarism',
      to: routeToHref(toPlagiarism()),
      header: 'Catch unintentional plagiarism',
      context:
        'Mistakes happen. Find and fix them before your teacher does with our helpful plagiarism checker.',
      dataTestId: tabTestIds.PLAGIARISM_TAB,
      type: TYPE_PLAGIARISM,
    },
  ],
})

export const oldPricingTableData = ({ countryCode }): PricingTable => {
  const locationToUse = getLocation(countryCode)
  return {
    title: 'Choose your online writing help',
    features: [
      { id: 'citationStyle', name: 'Citation styles' },
      { id: 'createBibliographies', name: 'Create bibliographies' },
      { id: 'grammarChecks', name: 'Grammar checks' },
      { id: 'saveCitations', name: 'Save your citations' },
      { id: 'plagiarismCheck', name: 'Plagiarism check' },
      { id: 'expertPaperHelp', name: 'Expert help for your paper' },
      { id: 'exportToWord', name: 'Export citations to Word' },
    ],
    products: [
      {
        id: PRICING_TABLE_FREE,
        name: 'Cite This For Me™ free account',
        price: 'Free',
        features: {
          citationStyle: '7000+ styles including Harvard and APA',
          grammarChecks: 'Only first 5 errors checked',
          saveCitations: 'Only for 7 days',
          createBibliographies: 'Up to 15 references',
        },
      },
      {
        id: PRICING_TABLE_PLUS,
        name: 'Cite This For Me™ Premium',
        price: `${LocationBasedPlan[locationToUse].price}/mo.`,
        recommended: true,
        features: {
          citationStyle: '7000+ styles including Harvard and APA',
          grammarChecks: 'Unlimited',
          saveCitations: 'Forever',
          createBibliographies: 'Unlimited',
          plagiarismCheck: CHECK,
          expertPaperHelp: '30 papers a month',
          exportToWord: CHECK,
        },
      },
    ],
  }
}

export const newPricingTableData = ({ countryCode }): PricingTable => {
  const locationToUse = getLocation(countryCode)
  return {
    features: [
      { id: CITATION_STYLE, name: 'Citation style' },
      { id: ADD_FREE_EXPERIENCE, name: 'Ad-free experience' },
      { id: PLAGIARISM_DETECTION, name: 'Plagiarism detection' },
      { id: EXPERT_PAPER_HELP, name: 'Expert help with your papers' },
      { id: GRAMMAR_CHECKS, name: 'Unlimited grammar check' },
      { id: SAVE_CITATIONS, name: 'Save citations' },
    ],
    products: [
      {
        id: PRICING_TABLE_FREE,
        name: 'Free account',
        price: 'Free',
        features: {
          citationStyle: 'Harvard Only',
          grammarChecks: CHECK,
          saveCitations: 'Only for 7 days',
        },
      },
      {
        id: PRICING_TABLE_PLUS,
        name: 'Cite This For Me™ Premium',
        price: `${LocationBasedPlan[locationToUse].price}/mo.`,
        recommended: true,
        features: {
          citationStyle: 'MLA, APA, Chicago, Harvard & 7,000 more',
          grammarChecks: CHECK,
          saveCitations: CHECK,
          plagiarismDetection: CHECK,
          adFreeExperience: CHECK,
          expertPaperHelp: '30 papers / month',
        },
      },
    ],
  }
}
